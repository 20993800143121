<template>
    <div>
        <v-dialog v-model="dialog" max-width="1200px" persistent scrollable>
        <v-card>
          <v-card-title class="dialog_card_title">
            <span class="headline font-weight-medium"> {{ title }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container grid-list-md>
                
                <v-row>

                <v-layout row wrap>
                    <LightBox
                        ref="lightbox"
                        :media="media"
                        :show-caption="true"
                        :show-light-box="false"
                    />
                    <v-flex xs12 sm12 md12 lg12>
                        <div class="div__divider__10"></div>
                       <p class="body-2 font-weight-regular card--text">Blejsko kartico in dovolilnico za dostop do Blejske promenade bo po novem možno pridobiti, podaljšati in plačati preko spletne aplikacije, ki je dostopna na naslovu <a href="https://dovolilnice.e-bled.si">https://dovolilnice.e-bled.si</a>.</p>
                        
                        <div class="div__divider__5"></div> 
                        <p class="body-2 font-weight-regular card--text">Dovolilnice lahko podaljšate <strong>IZKLJUČNO</strong> preko spleta. V primeru težav se obrnite na <a href="mailto:blejskakartica@bled.si">blejskakartica@bled.si</a> ali telefon 04 575 01 31.</p>

                        <div class="div__divider__5"></div> 
                        <!--<p class="body-2 font-weight-bold card--text">Letna dovolilnica velja od 1. maja tekočega leta do 30. aprila naslednjega leta, neglede na datum nakupa! Letno dovolilnico je možno podaljšati 30 dni pred iztekom veljavnosti.</p>-->
                        <p class="body-2 font-weight-bold card--text">Letna dovolilnica za parkiranje velja 12 mesecev od dneva izdaje. Cena letne dovolilnice za parkiranje osebnih vozil je 90 evrov. Popust za upravičence iz 42. člena Odloka o urejanju prometa v Občini Bled znaša 66,67%.</p>

                        <div class="div__divider__5"></div> 
                        <p class="body-2 font-weight-bold card--text">Za več splošnih informacij o sami Blejski kartici (kdo jo lahko kupi, kdo lahko uveljavlja popust, kako se uporablja) <a href="https://www.e-bled.si/splosne-informacije/" target="_blank">kliknite tukaj</a>.</p>

                        <div class="div__divider__15"></div> 
                        <h3>Blejska kartica - parkirna dovolilnica</h3>

                       <div class="div__divider__15"></div> 
                        <!--<p class="body-2 font-weight-regular card--text"><strong>Že obstoječi lastniki Blejske kartice, ki v sistem vstopate prvič</strong>, s klikom na gumb "Prijava" na prvi strani vpišite številko vaše kartice, ki je napisana na kartici. Kot “geslo” pa vpišite registrsko številko vašega vozila na katerega je kartica napisana in sicer brez presledkov in vezaja (npr. 0400 in KRDA465) (Slika 1 in Slika 2). Po prvi prijavi boste lahko vpisali nove podatke (e-pošto, geslo ipd.) </p>-->
                        <p class="body-2 font-weight-regular card--text">S klikom na gumb <strong>"Prijava"</strong> na prvi strani vpišite elektronski naslov in geslo, ki ste si ga nastavili ob prvi oddaji vloge. (Slika 1 in Slika 2). Po prvi prijavi boste lahko vpisali nove podatke (e-pošto, geslo ipd.)</p>

                        <div class="div__divider__5"></div> 
                        <!--<p class="body-2 font-weight-regular card--text"><strong>Lastniki Blejske kartice, ki ste v preteklosti že podaljšali dovolilnico preko spletne aplikacije</strong> https://dovolilnice.e-bled.si, s klikom na gumb "Prijava" vpišete vaš e-naslov in geslo, ki ste ga ustvarili pri prvi registraciji. V kolikor ste geslo pozabili, kliknite na ikono »POZABLJENO GESLO«.</p>-->
                        <p class="body-2 font-weight-regular card--text">V kolikor ste geslo pozabili, kliknite na ikono  <strong>»POZABLJENO GESLO«</strong>. Na vaš elektronski naslov boste prejeli povezavo, za ponastavitev gesla. V kolikor povzave ne prejmete, preverite npr. zavihek »promocije« ali vsiljeno pošto. V primeru težav se obrnite na <a href="mailto:blejskakartica@bled.si">blejskakartica@bled.si</a> ali telefon <strong>04 575 01 31</strong>.</p>

                        <div class="div__divider__5"></div>
                        <p class="body-2 font-weight-regular card--text">Po prijavi v sistem kliknite na ikono <strong>»Podaljšaj ali spremeni«</strong> (Slika 3). Odprla se vam bo vloga za podaljšanje dovolilnice. Vlogo skrbno preglejte, vnesite morebitne spremembe ter na koncu obkljukajte izjavo in kliknite <strong>»Oddaj vlogo«</strong>(Slika 4).</p>

                        <div class="div__divider__5"></div>
                        <p class="body-2 font-weight-regular card--text">Na vaš e-naslov boste dobili navodila za plačilo (Slika 5). Nakazilo lahko izvedete preko svoje spletne banke na transakcijski račun Občine Bled. Plačilo lahko izvedete na pošti ali banki z obrazcem UPN. Ko bo nakazilo izvedeno oziroma obdelano, bo dovolilnica avtomatsko podaljšana, na vaš e-naslov pa boste prejeli račun.</p>

                        <div class="div__divider__5"></div> 
                        <p class="body-2 font-weight-regular card--text">Prometno dovoljenje:</p>
                        <ul>
                            <li>Prometnega dovoljenja ni potrebno prilagati, če registrska številka ostane enaka.</li>
                            <li>V kolikor se je registrska številka spremenila, je potrebno naložiti fotografije pripadajočega prometnega dovoljenja.</li>
                        </ul>

                        <div class="div__divider__5"></div> 
                        <div class="div__divider__15"></div> 
                            <h3>Če še nimate Blejske kartice izberete “Izpolni vlogo” za izdajo Blejske kartice.</h3>
                        <div class="div__divider__15"></div> 

                        <div class="div__divider__5"></div> 
                        <p class="body-2 font-weight-regular card--text">Izpolnite vlogo, priložite fotokopijo prometnega dovoljenja ter na koncu obkljukajte izjavo in kliknite »Oddaj vlogo« .Na vaš e-naslov boste dobili navodila za plačilo (Slika 5). Ko bo nakazilo izvedeno oziroma obdelano, bo dovolilnica avtomatsko generirana, na vaš e-naslov pa boste prejeli račun. Po pošti vam bomo poslali Blejsko kartico, s katero boste lahko koristili možnosti uporabe parkomatov in javnih stranišč.</p>

                        <div class="div__divider__5"></div> 
                        <p class="body-2 font-weight-regular card--text">Iz istega uporabniškega računa je mogoče oddati vlogo za izdajo dodatne dovolilnice. Uporabnike z več obstoječimi dovolilnicami prosimo, da nas za prijavo v sistem kontaktirate na <a href="mailto:blejskakartica@bled.si">blejskakartica@bled.si</a>.</p>

                            <div class="div__divider__15"></div><br>
                            <div style="width:100%; margin:0 auto;">
                                <v-row justify="center">
                                    <v-col
                                    v-for="(image, index) in media"
                                    :key="index"
                                    class="d-flex child-flex"
                                    cols="2"
                                    @click="openGallery(index)"
                                    >
                                        <v-img
                                            :src="image.src"
                                            :lazy-src="image.src"
                                            aspect-ratio="1"
                                            class="grey lighten-2"
                                        >
                                            <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                                ></v-progress-circular>
                                            </v-row>
                                            </template>
                                        </v-img>
                                    </v-col>
                                </v-row>
                            </div>
                            <br>
                            <div class="div__divider__15"></div>

                        <div class="div__divider__10"></div> 
                        <p class="body-2 font-weight-regular card--text"><strong>Če imate težave s prijavo v sistem ali potrebujete pomoč vas prosimo, če nas kontaktirate na <a href="mailto:blejskakartica@bled.si">blejskakartica@bled.si</a> ali telefonsko številko: 04 575 01 00. </strong></p>

                        <div class="div__divider__15"></div> 
                            <h3>Cenik</h3>
                        <div class="div__divider__15"></div>
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        NAZIV
                                    </th>
                                    <th class="text-left">
                                        KOLIČINA
                                    </th>
                                        <th class="text-left">
                                        ENOTA
                                    </th>
                                        <th class="text-left">
                                        CENA V EUR (Z DDV)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Višina letne dovolilnice za parkiranje osebnih vozil (GOST)</td>
                                    <td class="text-center">1</td>
                                    <td class="text-center">KOS</td>
                                    <td class="text-center">90,00</td>
                                </tr>
                                <tr>
                                    <td>Višina letne dovolilnice za upravičence iz 42. člena Odloka o urejanju prometa v Občini Bled (DOMAČIN)</td>
                                    <td class="text-center">1</td>
                                    <td class="text-center">KOS</td>
                                    <td class="text-center">30,00</td>
                                </tr>
                                <tr>
                                    <td>Strošek kartice za letno dovolilnico (samo ob prvem nakupu)</td>
                                    <td class="text-center">1</td>
                                    <td class="text-center">KOS</td>
                                    <td class="text-center">10,00</td>
                                </tr>
                                <!--<tr>
                                    <td>Nadomestna kartica (ob izgubi ali poškodovanju obstoječe)</td>
                                    <td class="text-center">1</td>
                                    <td class="text-center">KOS</td>
                                    <td class="text-center">5,00</td>
                                </tr>-->
                            </tbody>
                            </template>
                        </v-simple-table>

                        <div class="div__divider__15"></div> 
                        <div class="div__divider__15"></div> 
                        <p class="body-2 font-weight-regular card--text">V primeru izgube ali poškodovanja kartice se obrnite na <a href="mailto:blejskakartica@bled.si">blejskakartica@bled.si</a> ali telefon 04 575 01 00. Na vaš e-naslov (ali po navadni pošti) vam bomo poslali predračun za izdajo nadomestne kartice. Cena nadomestne kartice je 10,00 EUR z DDV. Obdelava vloge in podaljšanje / izdelava dovolilnice bo izvedena v roku do 15 delovnih dni.</p>
                        <div class="div__divider__15"></div> 

                        <div class="div__divider__15"></div> 
                        <h3>Dovolilnica Velika Zaka</h3>
                       <div class="div__divider__15"></div> 

                       <div class="div__divider__5"></div>
                       <p class="body-2 font-weight-regular card--text">Fizične osebe s stalnim prebivališčem v občini Bled lahko pridobijo posebno dovolilnico »Velika Zaka«. Cena dovolilnice »Velika Zaka« je 10,00 EUR. Le ta bo omogočala prehod skozi območje Velike Zake v času urejanja prometa (od 15.6 do 15. 9 tekočega leta), ter celoletno brezplačno uporabo javnih sanitarij v lasti Občine Bled. Dovolilnica »Velika Zaka« ne omogoča uporabo parkirišč!</p>

                       <div class="div__divider__5"></div>
                       <p class="body-2 font-weight-regular card--text">Dovolilnico »Velika Zaka« se bo lahko pridobilo izključno preko spleta. Na začetni strani spletne aplikacije izberite »Izpolni vlogo za izdajo Blejske kartice«. Pri okencu »Izberi tip Blejske kartice« izberite možnost »Velika Zaka«, ter nadaljujte s postopkom oddaje vloge. Priložite fotokopijo prometnega dovoljenja iz katerega je razvidno stalno prebivališče vlagatelja. V primeru, da je vozilo v službeni uporabi fizične osebe s  stalnim prebivališčem v Občini Bled, je potrebno v postopku izdaje dovolilnice predložiti dokumentacijo, iz katere je razvidno, da oseba za njegovo uporabo plačuje  predpisano boniteto.  Na koncu obkljukajte izjavo in kliknite »Oddaj vlogo«. Na vaš e-naslov boste v nekaj minutah prejeli predračun oziroma navodila za plačilo. Lahko se zgodi, da vaš elektronski ponudnik (npr. Gmail), predračun zazna kot vsiljeno pošto ali promocijo. Če po oddaji vloge ne prejmete elektronske pošte, najprej preverite različne zavihke. Ko bo nakazilo obdelano, bo dovolilnica avtomatsko generirana, na vaš e-naslov pa boste prejeli račun. Kartico boste prejeli priporočeno po pošti.</p>

                       <p class="body-2 font-weight-regular card--text">V primeru težav se obrnite na <a href="mailto:blejskakartica@bled.si">blejskakartica@bled.si</a> ali telefon 04 575 01 31.</p>

                       <p class="body-2 font-weight-regular card--text">V kolikor boste že imeli veljavno oziroma aktivirano parkirno dovolilnico tipa »Domačin« ali »Gost/pravna oseba«, dodatne dovolilnice »Velika Zaka«, ne potrebujete. Stanovalci in lastniki nepremičnin v območju Velike Zake (med Pristavo in železniško postajo), ki ste kartice že prejeli v letu 2022 obveščamo, da bodo le te avtomatsko aktivirane. V kolikor ste kartico izgubili ali se je le ta poškodovala, pokličite oziroma pišite na zgoraj omenjeni  e-naslov.</p>

                        <div class="div__divider__15"></div> 

                        <div class="div__divider__15"></div> 
                        <h3>Dovolilnica za dostop na Blejsko promenado</h3>
                       <div class="div__divider__15"></div> 

                       <div class="div__divider__5"></div>
                       <p class="body-2 font-weight-regular card--text">Za uporabnike dovolilnic za dostop do Blejske promenade prijava z registrsko številko in številko kartice ni mogoča, zato morajo oddati novo vlogo s klikom na gumb "Izpolni vlogo".</p>

                       <p class="body-2 font-weight-regular card--text">Vlogo za izdajo dovolilnice lahko oddate tukaj:  <a href="https://dovolilnice.e-bled.si/blejska-promenada">https://dovolilnice.e-bled.si/blejska-promenada</a></p>

                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Po oddaji vlogi boste na email dobili potrditev o oddaji vloge. Če želite oddati več vlog (npr. dostavno podjetje z več dostavljalci), se prijavite v vaš uporabniški profil s podatki, ki ste jih vpisali ob prvi oddaji vloge in oddajte vloge za posamezne dovolilnice.</p>

                        <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Ko bo vaša vloga obdelana, boste na email dobili potrditev z navodili za uporabo.</p>

                        <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Pred potekom vaše dovolilnice vas bo sistem preko e-maila obvestil o možnosti podaljšanja dovolilnice preko vašega uporabniškega profila.</p>

                       <div class="div__divider__5"></div>
                       <p class="body-2 font-weight-regular card--text">Če imate težave s prijavo ali oddajo vloge, vas prosimo, da nas kontaktirate na <a href="mailto:promet@bled.si.">promet@bled.si.</a>.</p>

                       <div class="div__divider__15"></div> 
                        <h3>Navodila za uporabo potopnih stebričkov</h3>
                       <div class="div__divider__15"></div> 

                       <div class="div__divider__5"></div>
                       <p class="body-2 font-weight-regular card--text">Ob vstopu in izstopu je potrebno poklicati stebriček, in sicer tako, da vozilo obvezno stoji na zanki – tik pred stebričkom. Počakati je potrebno na zeleno luč na semaforju, nato lahko vozilo spelje. Potopni stebriček se bo po prevozu zanke nazaj avtomatsko zaprl.</p>

                       <div class="div__divider__5"></div>
                       <p class="body-2 font-weight-regular card--text">Telefonska številka stebrička:</p>
                       <p class="body-2 font-weight-regular card--text">Pod Jelovico: 041 386 824</p>

                       <div class="div__divider__5"></div>
                       <p class="body-2 font-weight-regular card--text">Za izhod ni potrebno klicati stebrička, odpira se avtomatsko. V kolikor bodo težave se prosim takoj obrnite na nas.</p>

                       <div class="div__divider__5"></div>
                       <p class="body-2 font-weight-regular card--text">Za ostala dva potopna stebrička pa je treba poklicati v obeh smereh:</p>
                       <p class="body-2 font-weight-regular card--text">Potopni stebriček fijakerji: 041397149</p>
                       <p class="body-2 font-weight-regular card--text">Potopni stebriček casino: 051305221</p>
                       <p class="body-2 font-weight-regular card--text">Potopni stebriček Mala Zaka: 031628207</p>

                       
                    </v-flex>
                </v-layout>
                </v-row>
            </v-container>
          </v-card-text>
        <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close()" color="error" text><v-icon left>mdi-close</v-icon>Zapri</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import pic1 from '@/assets/navodila_slika_1.png'
import pic2 from '@/assets/navodila_slika_2.png'
import pic3 from '@/assets/navodila_slika_3.png'
import pic4 from '@/assets/navodila_slika_4.png'
import pic5 from '@/assets/navodila_slika_5.png'

import LightBox from 'vue-it-bigger'
import 'vue-it-bigger/dist/vue-it-bigger.min.css'

export default {
    name: 'info-navodila-2021',
    components: {
        LightBox
    },

    data: () => ({
        title: 'Blejska kartica - oddaja vloge preko spleta',
        media:[
            /*{  For an image
                type: 'image', // Can be omitted for image
                thumb: 'https://img.youtube.com/vi/WsptdUFthWI/hqdefault.jpg',
                src: 'https://img.youtube.com/vi/WsptdUFthWI/hqdefault.jpg',
                caption: 'Caption to display. HTML <b>enabled</b>', // Optional
                srcset: '...' // Optional for displaying responsive images
            },*/
            {
                type: 'image',
                thumb: pic1,
                src: pic1,
                caption: 'Slika 1'
            },
            {
                type: 'image',
                thumb: pic2,
                src: pic2,
                caption: 'Slika 2'
            },
            {
                type: 'image',
                thumb: pic3,
                src: pic3,
                caption: 'Slika 3'
            },
            {
                type: 'image',
                thumb: pic4,
                src: pic4,
                caption: 'Slika 4'
            },
            {
                type: 'image',
                thumb: pic5,
                src: pic5,
                caption: 'Slika 5'
            }
        ]
    }),

    computed: {
        dialog: {
            set(dialogState) {
                this.$store.commit('SET_INFO_INSTRUCTIONS', dialogState)
            }, 

            get() {
                return this.$store.state.infoInstructions
            }
        },

        getPic1() {
            return pic1
        },
        getPic2() {
            return pic2
        },
        getPic3() {
            return pic3
        },
        getPic4() {
            return pic4
        },
        getPic5() {
            return pic5
        }

    },

    methods: {
        close() {
            this.$store.commit('SET_INFO_INSTRUCTIONS', false)
        },

        openGallery(index) {
            this.$refs.lightbox.showImage(index)
        }
    },

    mounted() {
    },

    destroyed() {

    }
}

</script>